const ProjectsData = [
    {
        projectName : "Programmers Army",
        imageUrl : "https://raw.githubusercontent.com/vishal-mourya/pro-army/main/pics/s1.png",
        description : "Programmers Army, is an open-community of coders, wherein any learner can get guidance, get knowledge",
        projectUrl : "https://programmersarmy.com/",
    },
    {
        projectName : "Vishal Portfolio",
        imageUrl : "https://raw.githubusercontent.com/vishal-mourya/vishal-portfolio/main/vishal-portfolio.png",
        description : "This portal provides all detailed information about Vishal and his interests and it lists down all the details about Vishal",
        projectUrl : "https://vishal-mourya.github.io/vishal-portfolio/",
    },
    {
        projectName : "World Class Free Education",
        imageUrl : "https://raw.githubusercontent.com/vishal-mourya/World-Class-Free-Education/main/pics/s1.png",
        description : "This portal provides a simply beautiful way to learn any topic, with the ease to access portal anywhere and anytime",
        projectUrl : "https://vishal-mourya.github.io/World-Class-Free-Education/index.html",
    },
    {
        projectName : "Short Karo URL Shortener",
        imageUrl : "https://raw.githubusercontent.com/vishal-mourya/short-karo-url-shortener/main/pics/s1.png",
        description : "A URL shortener is a tool or service that takes a long URL and creates a shorter, more manageable version that redirects to the original URL",
        projectUrl : "https://github.com/vishal-mourya/short-karo-url-shortener",
    },
    {
        projectName : "Alumni Student Connect App",
        imageUrl : "https://raw.githubusercontent.com/vishal-mourya/alumni-student-connect/main/UI1.png",
        description : "A Simple App made to connect college Alumni's with college students, for various reasons like Guidance, Support",
        projectUrl : "https://github.com/vishal-mourya/alumni-student-connect",
    },
    {
        projectName : "Flash Chat - Chatting App",
        imageUrl : "https://raw.githubusercontent.com/vishal-mourya/Flash-Chat-Flutter-Firebase/main/images/flash_chat_UI.png",
        description : "A Simple App which allows any user to register them and get authorized to chat with others present on the server.",
        projectUrl : "https://github.com/vishal-mourya/Flash-Chat-Flutter-Firebase/tree/main",
    },
];

export default ProjectsData;