import React, { useEffect } from 'react';
import 'wow.js/css/libs/animate.css';
import 'jquery.counterup';
import OurServicesData from '../../data/serviceData';
import ServiceCard from '../serviceCard/serviceCard';
import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';

const OurServices = () => {
    return (
    <>
        <div className="container-xxl bg-light py-5 my-5">
            <div className="container py-5">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                    <h1 className="display-6">Our Services</h1>
                    <p className="text-primary fs-5 mb-5">Unlock the potential of your digital journey with us</p>
                </div>
                <div className="row g-4">
                    {OurServicesData.map((service, index) => (
                        <ServiceCard
                            key={index}
                            serviceName={service.serviceName}
                            imageUrl={service.imageUrl}
                            description={service.description}
                        />
                    ))}
                </div>
            </div>
        </div>
    </>
    );
};

export default OurServices;