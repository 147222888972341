import React, { useEffect } from 'react';
import 'wow.js/css/libs/animate.css';
import 'jquery.counterup';

const Navbar = ({homeActive, aboutActive, servicesActive, projectsActive, contactActive}) => {
    return (
        <>
        {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-grow text-primary" role="status"></div>
    </div> */}
    
    <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 px-4 px-lg-5">
        <a href="/" className="navbar-brand d-flex align-items-center">
            <h2 className="m-0 text-primary"><img className="img-fluid me-2" src="./img/logo-1.png" alt="" style={{width: '45px'}}/>InnovationWale</h2>
        </a>
        <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-4 py-lg-0">
                <a href="/" className={"nav-item nav-link" + (homeActive ? " active" : "")}>Home</a>
                <a href="/about" className={"nav-item nav-link" + (aboutActive ? " active" : "")}>About Us</a>
                <a href="/services" className={"nav-item nav-link" + (servicesActive ? " active" : "")}>Our Services</a>
                <a href="/projects" className={"nav-item nav-link" + (projectsActive ? " active" : "")}>Projects Delivered</a>
                {/* <div className="nav-item dropdown">
                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                    <div className="dropdown-menu shadow-sm m-0">
                        <a href="feature.html" className="dropdown-item">Feature</a>
                        <a href="token.html" className="dropdown-item">Token Sale</a>
                        <a href="faq.html" className="dropdown-item">FAQs</a>
                        <a href="404.html" className="dropdown-item">404 Page</a>
                    </div>
                </div> */}
                <a href="/contact" className={"nav-item nav-link" + (contactActive ? " active" : "")}>Contact Us</a>
            </div>
            <div className="h-100 d-lg-inline-flex align-items-center d-none">
                <a className="btn btn-square rounded-circle bg-light text-primary me-1" href="https://www.linkedin.com/company/innovationwale/" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                <a className="btn btn-square rounded-circle bg-light text-primary me-1" href="https://www.facebook.com/people/InnovationWale/61556752913442/" target="_blank"><i className="fab fa-facebook-f"></i></a>
                <a className="btn btn-square rounded-circle bg-light text-primary me-2" href="https://twitter.com/InnovationWale" target="_blank"><i className="fab fa-twitter"></i></a>
            </div>
        </div>
    </nav>
    </>
    );
};

Navbar.defaultProps = {
    homeActive: false,
    aboutActive: false,
    servicesActive: false,
    projectsActive: false,
    contactActive: false
}

export default Navbar;

