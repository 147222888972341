const OurServicesData = [
    {
        serviceName : "Web Development",
        imageUrl : "img/web-development-1.png",
        description : "Transforming your digital presence with visually stunning, user-friendly websites optimized for functionality across all devices.",
    },
    {
        serviceName : "Mobile App Development",
        imageUrl : "img/app-development.png",
        description : "Empowering your brand with innovative mobile applications that enhance user engagement and accessibility on the go.",
    },
    {
        serviceName : "Artificial Intelligence and Machine Learning",
        imageUrl : "img/coding.png",
        description : "Developing Softwares powered with AI and ML technologies, data analysis, prediction, NLP",
    },
    {
        serviceName : "Enterprise Software Solutions",
        imageUrl : "img/enterprise.png",
        description : "Robust platforms designed to streamline operations, boost productivity, and drive growth for businesses of all sizes.",
    },
    {
        serviceName : "UI/UX Design",
        imageUrl : "img/ux.png",
        description : "Designing intuitive and user-friendly interfaces for software applications, ensuring an optimal user experience, that will make your brand stand out",
    },
    {
        serviceName : "Many More Services...",
        imageUrl : "img/customer-service.png",
        description : "Unlock limitless possibilities From web development to mobile apps and beyond, we offer many more services to propel your business to new heights.",
    },
];

export default OurServicesData;