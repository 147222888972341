import React, { useEffect } from 'react';
import 'wow.js/css/libs/animate.css';
import 'jquery.counterup';
import Navbar from '../components/navbar/navbar';
import ServiceAbout from '../components/servicepage/service';
import Footer from '../components/footer/footer';
import VishalCard from '../components/VishalCard/VishalCard';
import Contact from '../components/contactForm/contact';
import data from "../data/ProfileData";

const AboutUs = () => {
    return (
        <>
            <Navbar 
                aboutActive={true}
            />
            <h1 className='text-center'>About Us</h1>

            {data.map((person, index) => (
                <VishalCard 
                    key={index}
                    imageUrl={person.imageUrl}
                    title={person.name}
                    description={person.bio}
                    githubUrl={person.githubUrl}
                    linkedinUrl={person.linkedinUrl}
                />
            ))}
            <Contact/>
            <Footer />
        </>
    );
};

export default AboutUs;
