import React, { useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'wow.js/css/libs/animate.css';
import 'jquery.counterup';
import emailjs from '@emailjs/browser';


const Contact = () => {
  const form = useRef();
  const [contactNumber, setContactNumber] = useState(false);
  const [isInvalidInput, setIsInvalidInput] = useState(false);

  const handleContactNumberChange = (e) => {
    const inputValue = e.target.value;
    const isNumberValid = /^[0-9+]*$/.test(inputValue);
    
    setContactNumber(!isNumberValid);
  };

  const handlemailvalidation = (e) => {

    const inputValue = e.target.value;
    const isEmailValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(inputValue);

    // Check if the input is valid (contains only numeric characters and is a valid email)
    setIsInvalidInput(!isEmailValid);

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if any required fields are empty
    if (contactNumber || !document.querySelector('input[placeholder="First Name"]').value || !document.querySelector('input[placeholder="Last Name"]').value || !document.querySelector('input[placeholder="Email"]').value || !document.querySelector('textarea[placeholder="Enter Your Message....."]').value) {
      // Show a caution toast/alert if any required field is empty
      toast.warning('Please fill in all required fields before submitting.');
      return;
    }
  
    // Handle form submission only if the input is valid
    if (!isInvalidInput) {
        emailjs.sendForm('service_gcvfvgf', 'template_jmhd7up', form.current, 'XsJ-YuaDMe9X0GYLi')
      .then((result) => {
          console.log(result.text);
          toast.promise(
            new Promise((isResolved, reject) => setTimeout(isResolved, 2000)), // Simulate an asynchronous action
            {
              pending: 'Submitting...',
              success: 'Submitted! We will contact you shortly.',
              //  'Submitted! We will contact you shortly.'
              error: 'Oops! Something went wrong.',
            }
          );
      }, (error) => {
          console.log(error.text);
          toast.promise(
            new Promise((isResolved, reject) => setTimeout(reject, 2000)), // Simulate an asynchronous action
            {
              pending: 'Submitting...',
              success: 'Submitted! We will contact you shortly.',
              //  'Submitted! We will contact you shortly.'
              error: 'Oops! Something went wrong.',
            }
          );
      });

      // Clear form fields
      setContactNumber('');
      document.querySelector('input[placeholder="First Name"]').value = '';
      document.querySelector('input[placeholder="Last Name"]').value = '';
      document.querySelector('input[placeholder="Email"]').value = '';
      document.querySelector('textarea[placeholder="Enter Your Message....."]').value = '';
      document.querySelector('input[placeholder="Contact Number"]').value = '';

    //   toast.success('Submitted! They will contact you shortly.');
    }
  };
  

  return (
    <form ref={form}>
      <div className="container-fluid bg-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-3 justify-content-center">
            <div className="col-md-6 text-center">
              <h3 className="mb-4">Contact Us</h3>
              <p>Got questions or ready to start your next project? Reach out to our dedicated team today and let's turn your ideas into reality.</p>
              <div className="position-relative">
                <div className="row g-3">
                  <div className="col-md-6">
                    <input
                      className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                      type="text"
                      placeholder="First Name"
                      name='first_name'
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-control bg-transparent w-100 py-3 ps-4 pe-5 "
                      type="text"
                      placeholder="Last Name"
                      name='last_name'
                      required
                    />
                  </div>
                </div>
                <input
                  className="form-control bg-transparent w-100 py-3 ps-4 pe-5 mt-3"
                  type="text"
                  onChange={handlemailvalidation}
                  placeholder="Email"
                  name='email'
                  style={{ borderColor: isInvalidInput ? 'red' : '' }}
                  required
                />
                <textarea
                  className="form-control bg-transparent w-100 py-3 ps-4 pe-5 mt-3"
                  type="text"
                  name='message'
                  placeholder="Enter Your Message....."
                  required
                />
                <input
                  className="form-control bg-transparent w-100 py-3 ps-4 pe-5 mt-3"
                  type="tel"
                  name='contact_number'
                  onChange={handleContactNumberChange}
                  placeholder="Contact Number"
                  style={{ borderColor: contactNumber ? 'red' : '' }}
                  required
                />

                {/* Send button adjusted */}
                <div className="col-md-6 mt-3">
                  <button type="button" className="btn btn-primary py-2 px-4 w-100" onClick={handleSubmit}>
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      />

    </form>
  );
};

export default Contact;
