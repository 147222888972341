import React, { useState,useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'wow.js/css/libs/animate.css';
import 'jquery.counterup';
import emailjs from '@emailjs/browser';
const Footer = () => {
    const form = useRef();
 
    const [isInvalidInput, setIsInvalidInput] = useState(false);

    const handlemailvalidation = (e) => {

        const inputValue = e.target.value;
        const isEmailValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(inputValue);
    
        // Check if the input is valid (contains only numeric characters and is a valid email)
        setIsInvalidInput(!isEmailValid);
    
    };

    const handleSubscribe = (e) => {
        e.preventDefault();
        // Check if any required fields are empty
        if (!document.querySelector('input[placeholder="Your email"]').value) {
            console.log("h1");
            // Show a caution toast/alert if any required field is empty
            toast.warning('Please Enter your email before subscribing.');
            return;
        }
        console.log("h2");
    
      // Handle form submission only if the input is valid
        
          emailjs.sendForm('service_gcvfvgf', 'template_9lem9y8', form.current, 'XsJ-YuaDMe9X0GYLi')
        .then((result) => {
            console.log(result.text);
            document.querySelector('input[placeholder="Your email"]').value = '';
    
            toast.promise(
              new Promise((resolve) => setTimeout(resolve, 2000)), // Simulate an asynchronous action
              {
                pending: 'Subscribing...',
                success: 'Subscribed! You will here all bussiness related deal on time.',
                //  'Submitted! We will contact you shortly.'
                error: 'Oops! Something went wrong.',
              }
            );
        }, (error) => {
            console.log(error.text);
            console.log('error');
            toast.promise(
                new Promise((resolve) => setTimeout(resolve, 2000)), // Simulate an asynchronous action
                {
                  pending: 'Subscribing...',
                  success: 'Subscribed! You will here all bussiness related deal on time.',
                  //  'Submitted! We will contact you shortly.'
                  error: 'Oops! Something went wrong.',
                }
              );
        });
   
    };

    return (
        <form ref={form}>
            <div className="container-fluid bg-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-md-6">
                            <h1 className="text-primary mb-4"><img className="img-fluid me-2" src="./img/logo-1.png" alt="" style={{width: '45px'}}/>InnovationWale</h1>
                            <span>Empowering businesses with tailored software solutions. From websites, mobile apps to any software product, we prioritize your success. Trust in our expertise to transform your digital journey.</span>
                        </div>
                        <div className="col-md-6">
                            <h5 className="mb-4">Subscribe to our Newsletter!</h5>
                            <p>Subscribe now to receive the latest updates, industry insights, and exclusive offers from our innovative software company</p>
                            <div className="position-relative">
                                <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" name='email_id' placeholder="Your email" onChange={handlemailvalidation} style={{ borderColor: isInvalidInput ? 'red' : '' }}/>
                                <button type="button" className="btn btn-primary py-2 px-3 position-absolute top-0 end-0 mt-2 me-2" onClick={handleSubscribe}>Subscribe</button>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="mb-4">Get In Touch</h5>
                            {/* <p><i className="fa fa-map-marker-alt me-3"></i>123 Street, New York, USA</p> */}
                            <p><i className="fa fa-phone-alt me-3"></i>+1 (812) 778-5427</p>
                            <p><i className="fa fa-envelope me-3"></i>innovationwale@gmail.com</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="mb-4">Our Services</h5>
                            <a className="btn btn-link" href="/services">Web Development</a>
                            <a className="btn btn-link" href="/services">Mobile App Development</a>
                            <a className="btn btn-link" href="/services">Artificial Intelligence and Machine Learning</a>
                            <a className="btn btn-link" href="/services">Enterprise Software Solutions</a>
                            <a className="btn btn-link" href="/services">UI/UX Design</a>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="mb-4">Quick Links</h5>
                            <a className="btn btn-link" href="/">Home</a>
                            <a className="btn btn-link" href="/about">About Us</a>
                            <a className="btn btn-link" href="/services">Our Services</a>
                            <a className="btn btn-link" href="/projects">Projects Delivered</a>
                            <a className="btn btn-link" href="/contact">Contact Us</a>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="mb-4">Follow Us</h5>
                            <div className="d-flex">
                                <a className="btn btn-square rounded-circle me-1" href="https://www.linkedin.com/company/innovationwale/" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                <a className="btn btn-square rounded-circle me-1" href="https://www.facebook.com/people/InnovationWale/61556752913442/" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square rounded-circle me-1" href="https://twitter.com/InnovationWale" target="_blank"><i className="fab fa-twitter"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a href="#">InnovationWale</a>, All Right Reserved.
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      />
        </form>
    );
};

export default Footer;