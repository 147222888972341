import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from "./components/Home"
import About from './pages/aboutus';
import ServicesPage from './pages/services_page';
import ProjectsPage from './pages/projects_page';
import ContactPage from './pages/contact_page';

function App() {
  return (
    // Delete this comment
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/about" element={<About />}>
          <Route index element={<About />} />
        </Route>
        <Route path="/services" element={<ServicesPage />}>
          <Route index element={<ServicesPage />} />
        </Route>
        <Route path="/projects" element={<ProjectsPage />}>
          <Route index element={<ProjectsPage />} />
        </Route>
        <Route path="/contact" element={<ContactPage />}>
          <Route index element={<ContactPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
