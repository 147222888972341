const data = [
    {
        name : "Vishal Mourya",
        imageUrl : "https://media.licdn.com/dms/image/C4D03AQGUlIlPJbptUQ/profile-displayphoto-shrink_200_200/0/1650399841621?e=1713398400&v=beta&t=1XIghCTvI1rWsyhHQ8wYQPO5r84BaRbRoJOUE5CmEAA",
        bio : "As an accomplished Engineer and Coding maestro, I'm dedicated to crafting cutting-edge solutions. With a stellar 6 ⭐ rating on HackerRank, my coding prowess is second to none. As an ACM-ICPC Regionalist and former President of the esteemed CodeChef TCET Chapter, I thrive in the most challenging coding environments. Proudly spearheading the vibrant ProgrammersArmy Community, I'm committed to delivering software solutions that redefine excellence. With an impressive track record of over 30+ projects completed, each adorned with a glowing 5-star rating, I bring a wealth of experience and expertise to the table. I serve as an Engineer, leveraging the latest technologies to drive innovation and success. Join me on a journey where precision meets creativity, and every line of code tells a compelling story of triumph. Let's build the future of technology together - where excellence is not just a goal, but a guarantee.",
        githubUrl : "https://github.com/vishal-mourya",
        linkedinUrl: "https://www.linkedin.com/in/vishal-mourya-a4245b18b/"
    },
    {
        name : "Durgesh Tiwari",
        imageUrl : "https://media.licdn.com/dms/image/D4E03AQEfY6aND7Bdew/profile-displayphoto-shrink_400_400/0/1705295360891?e=1714003200&v=beta&t=YhUKweDhu5LhdYd1SxzXYqP1PiPmviqWEesjXA0FaqY",
        bio : "As a dedicated Software and Data Engineer, I bring a wealth of expertise honed during my tenure at Bytelearn and my recognition as an ICPC Asia West Regionalist in 2020. With a steadfast commitment to shaping the future of technology, I thrive on transforming intricate challenges into elegant solutions. My journey is characterized by a passion for innovation and analytical prowess, where each line of code contributes to building a smarter, more connected world. Join me in the pursuit of excellence, where data transcends into actionable insights, and software solutions redefine the status quo. With a track record of completing over 20+ projects, all garnished with glowing 5-star ratings, I assure you of nothing but the finest in software development. Let's embark on this journey together, where possibilities are limitless, and success is inevitable.",
        githubUrl : "https://github.com/blackhat955",
        linkedinUrl: "https://www.linkedin.com/in/durgesh98/"
    },
    {
        name : "Gopi Mehta",
        imageUrl : "https://media.licdn.com/dms/image/C4E03AQEEwTlzizyfiA/profile-displayphoto-shrink_800_800/0/1622037372572?e=1713398400&v=beta&t=mJsKrzyitgyzuVxVMWmiZrDUKo4Mnx6DgYpnJ16szj4",
        bio : "Having roots in Full Stack Development, I bring a wealth of experience from diverse industries, including automobile manufacturing, IT services, and non-profit organizations. My journey in the tech realm has led me to explore groundbreaking concepts like Self Sovereign Identities (SSIDs), poised to revolutionize our online interactions with institutions. With a robust background in Blockchain technology, particularly the Hyperledger Fabric framework by IBM, I've spent 2 years developing innovative solutions for various sectors, including MNCs and Forensics. Through these ventures, I've successfully delivered a plethora of applications, each tailored to solve real-life challenges and earn accolades with a solid 5-star rating. Beyond technical prowess, I pride myself on possessing practical leadership qualities and strategic planning acumen. My dedication to excellence and a penchant for innovation make me an invaluable asset in crafting bespoke software solutions for your needs. Join me on a journey where technology meets innovation, and let's build a future where possibilities are limitless. With over 25+ projects under my belt, all adorned with glowing 5-star reviews, I guarantee nothing but the best in software development.",
        githubUrl : "https://www.linkedin.com/in/gopimehta/",
        linkedinUrl: "https://www.linkedin.com/in/gopimehta/"
    },
];

export default data;