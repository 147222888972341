import React, { useEffect } from 'react';
import 'wow.js/css/libs/animate.css';
import jQuery from 'jquery';
import 'jquery.counterup';
import WOW from 'react-wow';

const Feature = () => {
    return (
        <>
  <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                <h1 className="display-6">Why Us!</h1>
                <p className="text-primary fs-5 mb-5">We specialize in transforming your digital aspirations into reality with our expert software solutions</p>
            </div>
            <div className="row g-5">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="d-flex align-items-start">
                        <img className="img-fluid flex-shrink-0" src="img/customer-satisfaction.png" alt="" height="60px" width="60px"/>
                        <div className="ps-4">
                            <h5 className="mb-3">Experience Excellence</h5>
                            <span>Tailored digital solutions crafted with precision to meet your unique business requirements.</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="d-flex align-items-start">
                    <img className="img-fluid flex-shrink-0" src="img/goal.png" alt="" height="60px" width="60px"/>
                        <div className="ps-4">
                            <h5 className="mb-3">Customer-Centric Approach</h5>
                            <span>Your success drives us - we're dedicated to exceeding your expectations at every turn.</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="d-flex align-items-start">
                    <img className="img-fluid flex-shrink-0" src="img/potential.png" alt="" height="60px" width="60px"/>
                        <div className="ps-4">
                            <h5 className="mb-3">Unleash Innovation</h5>
                            <span>Elevate your business with our state-of-the-art software solutions.</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="d-flex align-items-start">
                    <img className="img-fluid flex-shrink-0" src="img/medal.png" alt="" height="60px" width="60px"/>
                        <div className="ps-4">
                            <h5 className="mb-3">Proven Track Record</h5>
                            <span>Trusted by businesses globally, our history of success speaks volumes about our commitment to excellence.</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="d-flex align-items-start">
                    <img className="img-fluid flex-shrink-0" src="img/customer-behavior.png" alt="" height="60px" width="60px"/>
                        <div className="ps-4">
                            <h5 className="mb-3">Tailored Solutions</h5>
                            <span>Partner with our team of experts to unlock your business's full potential through personalized software solutions</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="d-flex align-items-start">
                    <img className="img-fluid flex-shrink-0" src="img/future.png" alt="" height="60px" width="60px"/>
                        <div className="ps-4">
                            <h5 className="mb-3">Future-Proof Your Business</h5>
                            <span>Stay ahead of the curve with our forward-thinking approach, ensuring your business is always prepared for what's next.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    );
};

export default Feature;