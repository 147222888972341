import React, { useEffect } from 'react';
import 'wow.js/css/libs/animate.css';
import 'jquery.counterup';
import ProjectsData from '../../data/projectsData';
import ProjectCard from '../projectCard/projectCard';
import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';

const ServiceAbout = () => {
    return (
    <>
        <div className="container-xxl bg-light py-5 my-5">
            <div className="container py-5">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                    <h1 className="display-6">Projects Delivered</h1>
                    <p className="text-primary fs-5 mb-5">Delivering innovative solutions that exceed expectations.</p>
                </div>
                <div className="row g-4">
                    {ProjectsData.map((project, index) => (
                        <ProjectCard 
                            key={index}
                            projectName={project.projectName}
                            imageUrl={project.imageUrl}
                            description={project.description}
                            projectUrl={project.projectUrl}
                        />
                    ))}
                </div>
            </div>
        </div>
    </>
    );
};

export default ServiceAbout;