import React from "react";
import './projectCard.css';

const ProjectCard = ({projectName, imageUrl, description, projectUrl}) => {
    return (
        <div className="col-lg-4 col-md-6 wow fadeInUp project-card-container" data-wow-delay="0.3s">
            <div className="bg-white p-5 custom-css">
                <img className="img-fluid mb-4" src={imageUrl} alt="project image" width="auto" height="auto"/>
                <h5 className="mb-3">{projectName}</h5>
                <p>{description}</p>
                <a href={projectUrl} target="_blank">Take a Look <i className="fa fa-arrow-right ms-2"></i></a>
            </div>
        </div>
    );
};

export default ProjectCard;