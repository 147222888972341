import React from "react";
import './serviceCard.css';

const ServiceCard = ({serviceName, imageUrl, description}) => {
    return (
        <div className="col-lg-4 col-md-6 wow fadeInUp project-card-container" data-wow-delay="0.3s">
            <div className="bg-white p-5 custom-css">
                <img className="img-fluid mb-4 service-img" src={imageUrl} alt="service image"/>
                <h5 className="mb-3">{serviceName}</h5>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default ServiceCard;