import React, { useEffect } from 'react';
import 'wow.js/css/libs/animate.css';
import 'jquery.counterup';
import Navbar from '../components/navbar/navbar';
import ServiceAbout from '../components/servicepage/service';
import Footer from '../components/footer/footer';
import VishalCard from '../components/VishalCard/VishalCard';
import Contact from '../components/contactForm/contact';
import data from "../data/ProfileData";
import OurServices from '../components/ourServices/OurServices';

const ProjectsPage = () => {
    return (
        <>
            <Navbar 
                    projectsActive={true}
            />
            <ServiceAbout />
            <Footer />
        </>
    );
};

export default ProjectsPage;
